.index {
  background: #4B89D2 url(../img/index_bg_m.jpg) no-repeat;
  background-size: cover;
  &-banner {
    position: relative;
    height: 350px;
    .ship {
      position: absolute;
      top: 55px;
      right: 100%;
      transition: 0.3s;
      background: url(../img/index_ship.png) no-repeat;
      background-size: contain;
      width: (1074 * 0.65) + 0px;
      height: (394 * 0.65) + 0px;
    }
  }
  &-about {
    padding: 2em 0 3.5em;
    &-title {
      max-width: 300px;
      margin-bottom: 0.5em;
      span {
        @include txt-hide;
        display: block;
        height: 0;
        background-image: url(../img/index_about_title_m.png);
        background-size: contain;
        padding-top: percentage(159 / 400);
      }
    }
    &-txt {
      line-height: (30 / 20);
      color: #fff;
      position: relative;
      &::after {
        content: '';
        display: block;
        background: url(../img/index_about_deco_m.png) no-repeat;
        background-size: contain;
        width: (144 * 0.75) + 0px;
        height: (134 * 0.75) + 0px;
        position: absolute;
        top: -22px;
        left: 260px;
      }
    }
  }
  &-news {
    position: relative;
    .deco {
      text-align: right;
      margin-bottom: 1.5em;
    }
    &-title {
      background: #fff;
      width: (105 / 18) + 0em;
      line-height: (38 / 18);
      text-align: center;
    }
    &-list {
      .box {
        margin: 10px auto 0;
        background: #fff;
        padding: 1.12em 1.5em;
        .title {
          font-weight: bold;
          @include one-line;
        }
        .date {
          color: #666;
          @include fz(15,18);
          margin-top: 0.3em;
        }
      }
      .box:hover {
        color: $brand-primary3;
      }
    }
    &-more {
      display: none;
    }
  }
}


@media (min-width: $screen-sm-min) {
  .index {
    background-image: url(../img/index_bg.jpg);
    &-banner {
      .ship {
        width: (1074 * 0.7) + 0px;
        height: (394 * 0.7) + 0px;
      }
    }
    &-about {
      margin-bottom: 200px;
      &-title {
        max-width: none;
        margin-bottom: 1em;
        span {
          background-image: url(../img/index_about_title.png);
          width: 484px;
          height: 154px;
          padding: 0;
        }
      }
      &-txt {
        @include fz(20px);
        &::after {
          background-image: url(../img/index_about_deco.png);
          width: 144px;
          height: 134px;
          left: 400px;
        }
      }
    }
    &-news {
      @include fz(16px);
      .deco {
        position: absolute;
        top: -152px;
        right: -16px;
      }
      &-list {
        position: relative;
        padding-right: 70px;
        @include clearfix;
        .item {
          width: 50%;
          float: left;
          padding-right: 10px;
        }
      }
      &-more {
        @include txt-hide;
        display: block;
        width: 70px;
        height: 88.8px;
        background: #465260 url(../img/index_news_more.png) 50% 50% no-repeat;
        background-size: cover;
        transition: 0.3s;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-top: 10px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .footer {
      background: none;
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 132px;
      &-copyright,
      &-phone {
        background: #fff;
        padding-left: 1.5em;
        padding-right: 1.5em;
        margin-bottom: 10px;
        margin-right: 10px;
      }
      &-contact {
        clear: left;
        margin-right: 10px;
        a {
          margin-right: 10px;
        }
      }
      &-author {
        color: #fff;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .index {
    &-banner {
      height: 450px;
      .ship {
        width: 1074px;
        height: 394px;
        top: 0;
      }
    }
    &-news {
      margin-left: 20%;
    }
    .footer {
      &-copyright,
      &-contact {
        margin-left: 20%;
      }
      &-contact {
        float: left;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .index {
    .main::after,
    .main .container::after {
      clear: none;
    }
    .main {
      .container {
        padding: 0;
        max-width: 1444px;
      }
    }
    &-about {
      float: left;
      margin-left: 40px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &-news {
      margin-left: 0;
      float: right;
      width: calc(100% - 680px);
      margin-top: 50px;
      margin-right: 40px;
      &-list {
        .item {
          width: 100%;
        }
      }
    }
    .footer {
      clear: right;
      @include clearfix;
      max-width: 1444px;
      margin: 0 auto;
      .container {
        padding: 0;
        float: right;
        width: calc(100% - 640px);
      }
      &-copyright,
      &-contact {
        margin-left: 0;
      }
      &-contact {
        clear: none;
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .index {
    .footer {
      &-contact {
        clear: left;
      }
    }
  }
}
@media (min-width: 1440px) {
  .index {
    &-news {
      // @include fz(18px);
      width: calc(100% - 740px);
      &-list {
        // padding-right: 87px;
        .item {
          width: 50%;
        }
      }
      // &-more {
      //   width: 87px;
      //   height: 100px;
      // }
    }
    .footer {
      .container {
        width: calc(100% - 700px);
      }
    }
  }
}
