.breadcrumb {
  @include list-reset;
  @include one-line;
  float: right;
  text-align: right;
  padding: 0.8em 1em 1em;
  display: none;
  color: #000;
  min-height: 55px;
  li {
    display: inline;
    vertical-align: top;
    + li::before {
      content: '> ';
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  li:last-child {
    a {
      color: $brand-primary;
    }
  }
}
.has-side {
  .breadcrumb {
    float: none;
    border-bottom: 1px solid #ccc;
  }
}

@media (min-width: $screen-sm-min) {
  .breadcrumb {
    display: block;
    li {
      a:hover {
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .breadcrumb {
  }
}
