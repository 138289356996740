.works {
  background: #35B3ED url(../img/works_bg.jpg) 50% 0 no-repeat;
  background-size: cover;
  .main {
    padding-top: 120px;
    &-title {
      span {
        padding-left: 0.5em;
      }
    }
  }
  &-list {
    text-align: center;
    .item {
      padding: 20px 40px;
    }
    .box {
      position: relative;
      display: block;
      span {
        display: block;
      }
      .pic {
        max-width: 290px;
        margin: 0 auto 1.5em;
        background: #fff;
        border: 5px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(#000,0.3);
        .img {
          padding-top: 100%;
          background: 50% 50% no-repeat;
          background-size: cover;
        }
        .img,
        img {
          transition: 0.3s;
        }
      }
      .title {
        color: $brand-primary;
        @include fz(18px);
        font-weight: bold;
        line-height: (24 / 18);
        margin: 0 auto 1.2em;
        max-width: 170px;
      }
      .more {
        display: inline-block;
        background: $brand-primary;
        color: #fff;
        line-height: 30px;
        width: 88px;
        border: 1px solid #fff;
        border-radius: 20px;
      }
    }
    .slick-prev,
    .slick-next {
      @include btn-reset;
      @include txt-hide;
      background: #fff url(../img/arrow.png) no-repeat;
      background-size: 200% auto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        background-color: $brand-primary;
        background-position-y: 100%;
      }
    }
    .slick-prev {
      left: -5px;
    }
    .slick-next {
      right: -5px;
      background-position: 100% 0;
    }
  }

}
body.mfp-open {
  overflow: hidden;
}
.works-detail {
  background: #fff;
  border-radius: 6px;
  &-mfp {
    .mfp-container {
      padding: 15px;
      .mfp-content {
        width: 100%;
        border-radius: 6px;
        max-width: 603px;
        margin: 0 auto;
        max-height: 100%;
      }
      .mfp-close {
        color: #808080;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        padding: 0;
        opacity: 1;
        font-size: 22px;
        &:hover {
          background: $brand-primary;
          color: #fff;
        }
      }
    }
    .mfp-iframe-box {
      max-width: 100%;
    }
    .mfp-iframe {
      height: 0;
      max-height: 100%;
      max-width: 100%;
      min-height: 100%;
      min-width: 100%;
      width: 0;
      border-radius: 6px;
    }
  }
  .gallery {
    .item {
      vertical-align: top;
      img {
        max-width: 100%;
      }
    }
    .slick-prev,
    .slick-next {
      @include btn-reset;
      @include txt-hide;
      background: #fff url(../img/arrow.png) no-repeat;
      background-size: 200% auto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        background-color: $brand-primary;
        background-position-y: 100%;
      }
    }
    .slick-prev {
      left: percentage(15 / 603);
    }
    .slick-next {
      right: percentage(15 / 603);
      background-position: 100% 0;
    }
  }
  .title {
    color: $brand-primary;
    @include fz(18px);
    line-height: 1.4;
    font-weight: bold;
    border-bottom: 1px solid #e9ecef;
    padding: 25px 25px 15px;
    margin: 0;
  }
  .txt {
    padding: 20px 25px;
  }
  .bt-box {
    text-align: right;
    border-top: 1px solid #e9ecef;
    padding: 15px 25px 20px;
  }
}

@media (min-width: $screen-sm-min) {
  .works {
    background-size: auto;
    &-list {
      padding: 0 1em;
      margin-bottom: 4em;
      .box:hover {
        text-decoration: none;
        .pic {
          img {
            transform: scale(1.2);
          }
        }
        .more {
          background: $brand-primary3;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min - 30px) {
  .works-detail {
    height: 454px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .content {
      min-height: 454px;
      margin-left: 603px;
      position: relative;
    }
    .gallery {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 603px;
      .slick-list {
        height: 100% !important;
        * {
          width: 100%;
          height: 100%;
        }
      }
      img {
        width: auto;
        height: auto;
      }
      .item {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
    .title {
      padding-top: 45px;
    }
    .txt {
      padding-bottom: 93px;
    }
    .bt-box {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: #fff;
    }
  }
}
@media (min-width: $screen-md-min) {
  .works {
    .main {
      padding-top: 40px;
      .container {
        max-width: 1390px;
      }
      &-title {
        margin-bottom: 0;
        top: 60px;
      }
    }
    &-list {
      padding: 0 2em;
      margin: 0 auto 6em;
      max-width: 1140px;
      .slick-prev,
      .slick-next {
        width: 40px;
        height: 40px;
      }
      .box {
        margin-top: 86px;
        margin-bottom: 114px;
      }
      .pos1 {
        .box {
          margin-top: 86px;
          margin-bottom: 114px;
        }
      }
      .pos2 {
        .box {
          margin-top: 200px;
          margin-bottom: 0;
        }
      }
      .pos3 {
        .box {
          margin-top: 0;
          margin-bottom: 200px;
        }
      }
    }
  }
  .works-detail {
    &-mfp {
      .mfp-iframe {
        max-height: 454px;
      }
      .mfp-container {
        .mfp-content {
          max-width: 1280px;
        }
      }
    }
    .title,
    .txt,
    .bt-box {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
@media (min-width: $screen-lg-min) {

}
