.bt {
  @include btn-reset;
  &:hover {
    text-decoration: none;
  }
  &-back {
    background: #fff;
    border: 1px solid #ccc;
    color: #999;
    @include fz(16px);
    width: 60px;
    height: 60px;
    line-height: 58px;
    border-radius: 50%;
    &:hover {
      background: $brand-primary;
      border-color: $brand-primary;
      color: #fff;
    }
  }
  &-normal {
    background: $brand-primary;
    color: #fff;
    line-height: (38 / 15);
    border-radius: 6px;
    padding: 0 1em;
    &:hover {
      background: $brand-primary2;
    }
  }
  &-simple {
    background: $brand-primary;
    color: #fff;
    line-height: 46px;
    padding: 0 2em;
    min-width: 120px;
    &:hover {
      background: #fff;
      color: $brand-primary;
    }
  }
}
.bt-box {
  clear: both;
  text-align: center;
  padding: 2em 0 0;
  margin: 0 auto;
  @include clearfix;
}
@media (min-width: $screen-sm-min) {
  .bt {
    &-simple {
      min-width: 200px;
    }
  }
  .bt-box {
    padding: 4.5em 0 0;
  }
}
@media (min-width: $screen-md-min) {

}
@media (min-width: $screen-lg-min) {
}
