.contact {
  background: #34B5EE url(../img/contact_bg.jpg) 50% 0 no-repeat;
  background-size: cover;
  .main {
    padding-top: 0;
    &-title {
      margin-bottom: 1.5em;
      span {
        padding-left: 0.4em;
        color: $brand-primary;
      }
    }
  }
  &-map {
    height: 200px;
    position: relative;
    margin-bottom: 2em;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  &-info {
    .item {
      margin-bottom: 1em;
    }
    .ic,
    .txt {
      display: inline-block;
      vertical-align: middle;
    }
    .ic {
      background: $brand-primary url(../img/contact_icon.png) no-repeat;
      background-size: auto 100%;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 1em;
      &.ic-addr {
        background-position: 0 0;
      }
      &.ic-phone {
        background-position: -50px 0;
      }
      &.ic-fax {
        background-position: -100px 0;
      }
      &.ic-mail {
        background-position: -150px 0;
      }
    }
    .txt {
      line-height: (24 / 15);
      color: $brand-primary;
      font-weight: bold;
      a {
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-form {
    border-top: 1px solid rgba(#fff,0.4);
    padding-top: 1.5em;
    margin: 2em auto 0;
  }
}


@media (min-width: $screen-sm-min) {
  .contact {
    background-size: auto;
    &-map {
      height: 250px;
    }
    &-info {
      @include clearfix;
      margin: 0 -1.2em;
      .item {
        float: left;
        width: 50%;
        padding: 0 1.2em;
      }
      .ic {
        width: 73px;
        height: 73px;
        &.ic-phone {
          background-position: -73px 0;
        }
        &.ic-fax {
          background-position: -146px 0;
        }
        &.ic-mail {
          background-position: -219px 0;
        }
      }
    }
    &-form {
      .row {
        @include clearfix;
        margin: 0 -1.2em;
        .col {
          float: left;
          width: 33.33%;
          padding: 0 1.2em;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .contact {
    .main {
      padding-top: 25px;
      .container {
        max-width: 1440px;
      }
      &-title {
        float: left;
      }
    }
    &-map {
      height: 308px;
      margin-bottom: 4em;
    }
    &-info {
      margin-left: 300px;
      padding-top: 2%;
    }
    &-form {
      max-width: 1135px;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .contact {
    &-info {
      margin-left: 375px;
      .item {
        width: auto;
      }
    }
  }
}
