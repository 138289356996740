.about {
  background: #011945 url(../img/about_bg_m.jpg) 50% 0 no-repeat;
  background-size: 768px auto;
  .main {
    padding-top: 200px;
    &-title {
      max-width: 410px;
    }
  }
  &-content {
    background: #fff;
    border-radius: 5px;
  }
  &-title {
    color: $brand-primary;
    @include fz(17px);
    font-weight: bold;
    padding: 35px 25px 16px;
    margin: 0;
  }
  &-txt {
    border-top: 1px solid #e9ecef;
    padding: 20px 25px 30px;
    color: #666;
  }
}

@media (min-width: $screen-sm-min) {
  .about {
    background-image: url(../img/about_bg.jpg);
    background-attachment: fixed;
    background-size: 125% auto;
    .main {
      padding-top: 5%;
      .container {
        max-width: 1520px;
      }
      &-title {
        position: fixed;
        top: 0;
        padding-top: 23%;
      }
    }
    &-content {
      margin-left: 45%;
      max-width: 720px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .about {
    &-title {
      @include fz(20px);
      padding: 45px 65px 15px;
    }
    &-txt {
      padding: 20px 65px 45px;
    }
  }
}
@media (min-width: 1520px) {
  .about {
    background-size: auto;
    .main {
      padding-top: 100px;
      &-title {
        padding-top: 350px;
      }
    }
  }
}
