.field {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0 0.5em;
  border: 0;
  background: #fff;
  margin-bottom: 0.3em;
  &:focus {
    outline: none;
    box-shadow: inset 2px 2px 6px rgba(#000,0.3);
  }
  @include field-placeholder {
    color: $input-color-placeholder;
  }
}
textarea.field {
  height: auto;
  line-height: 1.4;
  padding: 0.6em 0.5em;
}
.has-error .field {
  border-color: $brand-danger;
}
.has-error,
.has-error .field-label {
  color: $brand-danger;
}
.field-group {
  padding-bottom: 6px;
  line-height: 38px;
  position: relative;
  .field {
    padding-left: 3.5em;
  }
  .field-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0.5em;
  }
}
.field-box {
  position: relative;
}
.field-label {
  color: $brand-primary;
  display: block;
}
.field-inline {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.3em;
  margin-right: 0.5em;
  position: relative;
  label {
    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.5em 0.1em 0;
      background: url(../img/form_radio.png) no-repeat;
      width: 15px;
      height: 15px;
    }
  }
  input {
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    &:checked + label {
      &::before {
        background-position: 0 100%;
      }
    }
  }
}
.field-set {
  display: table;
  margin-bottom: 0.3em;
  > * {
    display: table-cell;
    vertical-align: middle;
  }
  .field {
    margin: 0;
  }
  .txt {
    padding: 0 1em;
    width: 1%;
  }
}
.form-box {
  padding: 1.5em 0;
}
.form-reminder {
  color: #999;
  line-height: (24 / 16);
}
.form-bt-box {
  padding: 1.5em 0 0;
  letter-spacing: 1.5em;
}

@media (min-width: $screen-sm-min) {
  .field-label {
    float: left;
  }
  .field-box {
    margin-left: 5.5em;
  }
  .field-group {
    padding-bottom: 1.2em;
  }
  .form-reminder {
    color: $text-color;
  }
  .form-bt-box {
    letter-spacing: 3.5em;
  }
}
