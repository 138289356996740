.header {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  .header-fixed & {
    background: #fff;
    box-shadow: 0 0 10px rgba(#000,0.2);
  }
  .container {
    padding: 0;
  }
  .site-title {
    margin: 0;
    padding: 12px 20px;
    font-size: 1em;
    .logo {
      @include txt-hide;
      vertical-align: top;
      background-image: url(../img/logo.svg);
      background-size: auto 100%;
      width: (290 * 0.7) + 0px;
      height: (63 * 0.7) + 0px;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.nav {
  background: $brand-primary;
  position: fixed;
  z-index: 150;
  top: 66px;
  left: 0;
  right: 0;
  bottom: 100%;
  overflow: hidden;
  pointer-events: visible;
  transition: 0.3s;
  &-open {
    overflow: hidden;
  }
  .nav-open & {
    border-top: 6px solid $brand-primary2;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &-toggle {
    position: absolute;
    z-index: 200;
    top: 8px;
    right: 10px;
    background: #fff;
    width: 50px;
    height: 50px;
    .ic {
      &-bar {
        background: #333;
        width: 20px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -10px;
        transform-origin: center;
        transition: 0.3s;
        &:nth-of-type(1) {
          transform: translateY(-7px);
        }
        &:nth-of-type(2) {
          transform: translateY(7px);
        }
      }
    }
    .nav-open & {
      .ic {
        &-bar {
          &:nth-of-type(1) {
            transform: rotate(45deg);
          }
          &:nth-of-type(2) {
            transform: rotate(-45deg);
          }
          &:nth-of-type(3) {
            display: none;
          }
        }
      }
    }
  }
  &-inner {
    padding: 1em 2em 2em;
  }
  .menu {
    > li {
      > a {
        position: relative;
        color: rgba(#fff,0.4);
        overflow: hidden;
        @include fz(12px);
        padding: 2.8em 1.5em 2em;
        border-bottom: 1px solid rgba($brand-primary2,0.3);
        span {
          float: left;
          color: #fff;
          @include fz(18,12);
          margin: -0.4em 0.8em 0 0;
        }
      }
      > a:hover {
        span {
          color: $brand-primary2;
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .header {
    .site-title {
      padding: 18px 25px;
      float: left;
      .logo {
        width: (290 * 0.85) + 0px;
        height: (63 * 0.85) + 0px;
      }
    }
  }
  .nav {
    .nav-open & {
      top: 100px;
    }
    &-toggle {
      top: 20px;
      right: 25px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .header {
    padding-top: 10px;
    .header-fixed & {
      padding-top: 0;
    }
    .site-title {
      padding-top: 25px;
      .header-fixed & {
        padding-top: 15px;
        padding-bottom: 12px;
      }
    }
  }
  .nav {
    background: none;
    float: right;
    &-open {
      overflow: visible;
    }
    &,
    .nav-open & {
      position: static;
      overflow: visible;
      border-top: 0;
    }
    &-toggle {
      display: none;
    }
    &-inner {
      padding: 0;
    }
    .menu {
      text-align: right;
      > li {
        float: left;
        margin-right: 30px;
        > a {
          border-bottom-color: transparent;
          color: rgba($brand-primary,0.4);
          line-height: 1.4;
          padding: 8px 16px 5px;
          span {
            float: none;
            margin: 0;
            display: block;
            color: $brand-primary;
            @include fz(16,12);
            font-weight: bold;
          }
          &::before {
            content: '';
            display: block;
            background: url(../img/menu_current.png) no-repeat;
            background-size: contain;
            width: (23 * 0.7) + 0px;
            height: (26 * 0.7) + 0px;
            margin: 0 auto 0.5em;
            opacity: 0;
            transition: 0.3s;
          }
        }
      }
      > li.current > a,
      > li > a:hover {
        border-bottom-color: $brand-primary;
        span {
          color: $brand-primary;
        }
      }
      > li.current > a {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .header {
    .container {
      max-width: 1500px;
    }
    .site-title {
      padding-left: 40px;
    }
  }
}
