.wrapper {
  padding-top: 70px;
}
.container {
  margin: 0 auto;
  padding: 0 25px;
}
img.has-border {
  border: 1px solid #d2d2d2;
}
.main {
  position: relative;
  padding-top: 50px;
  &-title {
    color: #fff;
    margin: 0 auto 3em;
    font-size: 1em;
    position: relative;
    img {
      max-height: 55px;
    }
    span {
      display: block;
      padding-left: 1.4em;
      @include fz(17px);
      font-weight: bold;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .wrapper {
    padding-top: 100px;
  }
  .main {

  }
}
@media (min-width: $screen-md-min) {
  .wrapper {
    padding-top: 136px;
  }
  .main {
    &-title {
      img {
        max-height: 70px;
      }
      span {
        @include fz(20px);
        padding-left: 1.8em;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .main {
    &-title {
      img {
        max-height: none;
      }
      span {
        padding-left: 2.4em;
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .main {
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
