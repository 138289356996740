.product,
.product2 {
  background: url(../img/product_bg.jpg) 50% 0 no-repeat;
  background-size: cover;
  .main {
    &-title {
      display: inline-block;
      img {
        max-height: 96px;
      }
      span {
        padding-left: 0.4em;
      }
    }
  }
}
.product {
  .main {
    &-title {
      &::after {
        content: '';
        display: block;
        background: url(../img/product_title_deco.png) no-repeat;
        background-size: contain;
        width: (144 * 0.6) + 0px;
        height: (134 * 0.6) + 0px;
        position: absolute;
        bottom: -50px;
        left: percentage(315 / 395);
      }
    }
  }
  &-list {
    .item {
      clear: both;
      .box {
        color: $brand-primary;
        background: rgba(#fff,0.7);
        border-radius: 50%;
        position: relative;
        @include fz(18px);
        font-weight: bold;
        text-align: center;
        margin-bottom: 2em;
        &:hover {
          color: #fff;
          background: $brand-primary;
        }
      }
    }
    .item1 {
      .box {
        width: 235px;
        height: 235px;
        line-height: 235px;
        &::before {
          content: '';
          display: block;
          background: rgba(#fff,0.7);
          width: 106px;
          height: 106px;
          border-radius: 50%;
          position: absolute;
          top: 155px;
          left: 155px;
        }
      }
    }
    .item2 {
      float: right;
      .box {
        background: rgba(#fff,0.9);
        width: 172px;
        height: 172px;
        line-height: 172px;
      }
    }
    .item3 {
      .box {
        width: 250px;
        height: 250px;
        line-height: 250px;
        margin: 0 auto;
        &::before {
          content: '';
          display: block;
          background: rgba(#fff,0.8);
          width: 108px;
          height: 108px;
          border-radius: 50%;
          position: absolute;
          top: -3px;
          left: 192px;
        }
        &::after {
          content: '';
          display: block;
          background: rgba(#fff,0.4);
          width: 74px;
          height: 74px;
          border-radius: 50%;
          position: absolute;
          top: 110px;
          right: -177px;
        }
      }
    }
  }
}

.product2 {
  background-image: url(../img/product_bg2.jpg);
  &-side {
    &-nav {
      position: relative;
      text-align: center;
      @include clearfix;
      margin: 0 auto 2em;
      padding-top: 85px;
      > li {
        > a {
          position: absolute;
          top: 0;
          left: 0;
          width: 33.33%;
          color: $brand-primary;
          @include fz(20,18);
          font-weight: bold;
          border-bottom: 1px solid rgba($brand-primary,0.4);
          padding: 10px 0 6px;
          .ic {
            display: block;
            background: url(../img/product_nav_icon.png) no-repeat;
            background-size: 200% auto;
            width: (100 * 0.5) + 0px;
            height: (80 * 0.5) + 0px;
            margin: 0 auto;
            &.ic1 {
              background-position: 0 0;
            }
            &.ic2 {
              background-position: 0 50%;
            }
            &.ic3 {
              background-position: 0 100%;
            }
          }
        }
        &:nth-child(2) > a {
          left: 33.33%;
        }
        &:nth-child(3) > a {
          left: 66.66%;
        }
        > ul {
          display: none;
          background: #fff;
          padding: 0.5em 0;
          > li {
            > a {
              position: relative;
              padding: 0.5em 2.8em;
              line-height: 1.4;
            }
          }
          > li > a:hover,
          > li.open > a,
          > li.current > a {
            color: $brand-primary3;
            &::before,
            &::after {
              content: '';
              display: block;
              width: 5px;
              height: 5px;
              border: 1px solid $brand-primary3;
              position: absolute;
              top: 50%;
            }
            &::before {
              transform: rotate(45deg) translate(-50%,-50%);
              left: 34px;
            }
            &::after {
              transform: rotate(-45deg) translate(50%,-50%);
              right: 34px;
            }
          }
        }
      }
      > li.open {
        > ul {
          display: block;
        }
      }
      > li > a:hover,
      > li.open > a,
      > li.current > a {
        background: linear-gradient(to right, #475461 0%,#29323c 100%);
        color: #fff;
        .ic {
          &.ic1 {
            background-position: 100% 0;
          }
          &.ic2 {
            background-position: 100% 50%;
          }
          &.ic3 {
            background-position: 100% 100%;
          }
        }
      }
    }
  }
  &-list {
    padding-top: 22px;
    text-align: center;
    .box {
      max-width: 310px;
      margin: 0 auto 1em;
      position: relative;
      box-shadow: 5px 5px 10px rgba(#000,0.16);
      &::before {
        content: '';
        display: block;
        background: #000;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: 0.3s;
      }
      .pic {
        padding-top: percentage(152 / 270);
        background: 50% 50% no-repeat;
        background-size: cover;
      }
      .title {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(#000,0.6);
        padding: 0.5em 1em;
        line-height: 1.35;
        color: #fff;
        @include fz(20px);
        transition: 0.3s;
      }
    }
    .box:hover {
      &::before {
        opacity: 0.6;
      }
      .title {
        opacity: 1;
      }
    }
    .item.head {
      .box {
        text-align: left;
        box-shadow: none;
        &::before {
          position: static;
          opacity: 0;
        }
        .title {
          position: relative;
          transform: none;
          margin: 0;
          padding: 0 1.2em 0 0;
          text-align: right;
          display: inline-block;
          @include fz(24px);
          background: none;
          opacity: 1;
          text-shadow: 5px 5px 10px rgba(#000,0.16);
          &::after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 9px;
            border-color: transparent transparent transparent #fff;
            position: absolute;
            right: 0.2em;
            top: 50%;
            margin-top: -6px;
          }
        }
      }
    }
  }
}
.product-detail {
  max-width: 800px;
  margin: 0 auto;
  .gallery {
    .img {
      padding-top: percentage(600 / 800);
      background: #fff 50% 50% no-repeat;
      background-size: contain;
    }
  }
  &-title {
    color: $brand-primary;
    @include fz(22px);
    font-weight: bold;
    line-height: 1.4;
    margin: 0;
    padding: 1em 0.5em;
  }
  &-title2 {
    background: $brand-primary;
    color: #fff;
    @include fz(20px);
    font-weight: bold;
    line-height: (32 / 24);
    width: (100 / 24) + 0em;
    padding: ((18 / 24) + 0em) 0;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: (-32 / 24) + 0em;
  }
  &-sect {
    position: relative;
    margin-top: 4em;
  }
  .gallery {
    .desc {
      color: #fff;
      @include fz(14px);
      background: rgba(#000,0.6);
      text-align: center;
      padding: 0.75em 1em;
    }
    .slick-prev,
    .slick-next {
      @include btn-reset;
      @include txt-hide;
      background: #fff url(../img/arrow.png) no-repeat;
      background-size: 200% auto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        background-color: $brand-primary;
        background-position-y: 100%;
      }
    }
    .slick-prev {
      left: percentage(20 / 800);
    }
    .slick-next {
      right: percentage(20 / 800);
      background-position: 100% 0;
    }
  }
  .spec {
    background: #fff;
    padding: 0.5em 1.5em;
    .product-detail-title2 {
      right: (-32 / 24) + 0em;
    }
    &-table {
      @include table-reset;
      width: 100%;
      tr, th, td {
        display: block;
      }
      tr {
        padding: 0.75em 0;
        + tr {
          border-top: 1px solid #c3c3c3;
        }
      }
      th {
        text-align: left;
        color: $brand-primary;
        font-weight: normal;
      }
    }
  }
  .arrange {
    padding: 1em;
    background: #fff;
    .product-detail-title2 {
      left: (-32 / 24) + 0em;
      padding: 1.5em 0;
    }
  }
  .video {
    .product-detail-title2 {
      right: (-32 / 24) + 0em;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .product {
    &-list {
      @include clearfix;
      .item {
        float: left;
        clear: none;
      }
      .item2 {
        padding: 235px 30px 50px 25px;
      }
      .item3 {
        padding-top: 25px;
      }
    }
  }
  .product2 {
    background-size: auto;
    background-color: #9FEDF1;
    &-side {
      &-nav {
        max-width: none;
      }
    }
    &-list {
      @include clearfix;
      margin: 0 -0.5em;
      .item {
        float: left;
        width: 33.33%;
        padding: 0 0.5em;
        &:nth-child(3n+2) {
          clear: left;
        }
      }
      .box {
        .title {
          background: none;
          bottom: 50%;
          transform: translateY(50%);
          opacity: 0;
        }
      }
      .item.head {
        width: 100%;
        .box {
          max-width: none;
          .title {
            @include fz(30px);
            &::after {
              border-width: 8px 0 8px 12px;
              margin-top: -8px;
            }
          }
        }
      }
    }
  }
  .product-detail {
    .gallery {
      .item {
        position: relative;
      }
      .desc {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .slick-prev,
      .slick-next {
        width: 40px;
        height: 40px;
      }
    }
    .spec {
      padding: 1.5em 2em;
      &-table {
        tr {
          display: table-row;
          + tr {
            border-top: 0;
          }
        }
        th, td {
          display: table-cell;
          vertical-align: top;
        }
        th {
          white-space: nowrap;
          border-right: 1px solid #c3c3c3;
          padding-right: 3em;
          width: 15%;
        }
        td {
          padding-left: 1.5em;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .product,
  .product2 {
    .main {
      .container {
        max-width: 1440px;
      }
      &-title {
        img {
          max-height: 120px;
        }
      }
    }
  }
  .product {
    .main {
      &-title {
        position: absolute;
        top: 0;
        padding-top: 304px;
        &::after {
          width: (144 * 0.8) + 0px;
          height: (134 * 0.8) + 0px;
          bottom: -70px;
        }
      }
    }
    &-content {
      margin-left: 220px;
      padding-bottom: 100px;
    }
    &-list {
      .item {
        .box {
          @include fz(24px);
        }
      }
    }
  }
  .product2 {
    .main {
      &-title {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
      }
      .bt-box {
        max-width: 600px;
      }
    }
    &-side {
      float: left;
      position: relative;
      padding: 180px 0 0 24px;
      &-nav {
        width: 200px;
        padding-top: 0;
        > li {
          margin-bottom: 1em;
          > a {
            position: static;
            width: auto;
            padding: 1em 0 0.8em;
            .ic {
              width: (100 * 0.8) + 0px;
              height: (80 * 0.8) + 0px;
            }
          }
          &:last-child {
            > a {
              border-bottom: 0;
            }
          }
        }
      }
    }
    &-content {
      float: right;
      width: calc(100% - 340px);
    }
    &-list {
      padding-top: 50px;
      margin: 0 -1em;
      .item {
        padding: 0 1em;
      }
      .box {
        margin-bottom: 2em;
      }
      .item.head {
        padding-top: 1em;
        .box {
          margin-bottom: 1.5em;
        }
      }
    }
  }
  .product-detail {
    max-width: 600px;
    &-sect {
      margin-top: 75px;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .product,
  .product2 {
    .main {
      .container {
        max-width: $screen-lg-min;
      }
      // &-title {
      //   img {
      //     max-height: none;
      //   }
      // }
      // .bt-box {
      //   max-width: 700px;
      //   float: right;
      //   width: 100%;
      //   margin-right: 40px;
      // }
    }
  }
  .product {
    // .main {
    //   &-title {
    //     &::after {
    //       width: 144px;
    //       height: 134px;
    //     }
    //   }
    // }
    &-content {
      // margin-left: 396px;
      margin-left: 350px;
    }
    &-list {
      .item2 {
        padding-left: 52px;
        padding-right: 40px;
      }
    }
  }
  // .product2 {
  //   &-side {
  //     padding-top: 242px;
  //     &-nav {
  //       width: 220px;
  //       @include fz(18px);
  //       > li {
  //         > a {
  //           .ic {
  //             width: 100px;
  //             height: 80px;
  //           }
  //         }
  //         > ul {
  //           > li {
  //             > a {
  //               padding: 0.5em 2.4em;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   &-content {
  //     width: calc(100% - 350px);
  //   }
  //   &-list {
  //     padding-top: 75px;
  //     margin: 0 -2em;
  //     .item {
  //       padding: 0 2em;
  //     }
  //     .box {
  //       margin-bottom: 4em;
  //       .title {
  //         @include fz(24px);
  //       }
  //     }
  //     .item.head {
  //       .box {
  //         .title {
  //           @include fz(40px);
  //         }
  //       }
  //     }
  //   }
  // }
  .product-detail {
    float: right;
    max-width: 700px;
    width: 100%;
    margin-right: 40px;
    &-title2 {
      @include fz(24px);
    }
    &-sect {
      margin-top: 110px;
    }
  }
}
@media (min-width: $screen-xl-min) {
  .product2 .main .bt-box,
  .product-detail {
    max-width: 800px;
  }
}
