.pagination {
  @include list-reset;
  display: inline-block;
  vertical-align: top;
  &-nav {
    text-align: center;
    padding: 2em 0 0;
    margin: 0 -25px;
  }
  li {
    float: left;
    a {
      display: block;
      color: inherit;
      color: #fff;
      background: #b5b5b5;
      min-width: 36px;
      height: 36px;
      line-height: 32px;
      border: 1px solid #fff;
      border-radius: 24px;
      padding: 0 5px;
      margin: 4px;
      transition: 0.3s;
      i {
        @include txt-hide;
        background: url(../img/pager_arrow.png) no-repeat;
        width: 15px;
        height: 15px;
        &.ic-prev {
          background-position: 0 0;
        }
        &.ic-next {
          background-position: 100% 0;
        }
      }
    }
  }
  li.current a,
  li a:hover {
    background: #fff;
    color: #7b5f47;
    text-decoration: none;
    i {
      &.ic-prev {
        background-position: 0 100%;
      }
      &.ic-next {
        background-position: 100% 100%;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .pagination {
    li {
      a {
        min-width: 48px;
        height: 48px;
        line-height: 44px;
      }
    }
  }
}
