.news {
  background: #34B5EE url(../img/news_bg.jpg) 50% 0 no-repeat;
  background-size: cover;
  .main {
    &-title {
      span {
        padding-left: 0.3em;
      }
    }
  }
  &-side {
    &-nav {
      position: relative;
      text-align: center;
      @include clearfix;
      margin: 0 auto 2em;
      > li {
        float: left;
        width: 50%;
        > a {
          color: $brand-primary;
          @include fz(20,18);
          font-weight: bold;
          border-bottom: 1px solid rgba($brand-primary,0.4);
          padding: 10px 0 6px;
        }
      }
      > li > a:hover,
      > li.current > a {
        background: linear-gradient(to right, #475461 0%,#29323c 100%);
        color: #fff;
      }
    }
  }
  &-list {
    .box {
      background: #fff;
      border-radius: 5px;
      padding: 1em 1.6em;
      margin-bottom: 1em;
      line-height: 1.3;
      .date {
        margin-bottom: 0.5em;
      }
    }
    .box:hover {
      color: $brand-primary3;
    }
  }
  &-detail {
    background: #fff;
    border-radius: 5px;
    &-title {
      color: $brand-primary;
      @include fz(17px);
      font-weight: bold;
      padding: 35px 25px 16px;
      margin: 0;
    }
    &-txt {
      border-top: 1px solid #e9ecef;
      padding: 20px 25px 30px;
      color: #666;
    }
    &-video {
      border-top: 1px solid #e9ecef;
      padding: 25px;
    }
    .share {
      border-top: 1px solid #e9ecef;
      text-align: right;
      padding: 20px 25px 30px 20px;
      .related-link {
        display: block;
        width: 6em;
        margin-bottom: 0.5em;
        &::after {
          content: '';
          display: inline-block;
          vertical-align: text-bottom;
          background: url(../img/link_icon.png) no-repeat;
          width: 16px;
          height: 16px;
          margin: 0 0 0.1em 0.5em;
        }
        &:hover {
          color: $brand-primary;
          &::after {
            background-position: 0 100%;
          }
        }
      }
      .label {
        @include fz(14px);
        font-weight: bold;
        margin-right: 0.5em;
      }
      .label,
      .a2a_kit {
        display: inline-block;
        vertical-align: middle;
      }
      .a2a_kit {
        .a2a_svg {
          border-radius: 50%;
          padding: 3px;
        }
      }
    }
  }
}

@media (min-width: $screen-xs-min) {
  .news-detail {
    .share {
      .related-link {
        float: left;
        margin-top: 0.3em;
      }
    }
  }
}
@media (min-width: $screen-sm-min) {
  .news {
    background-size: auto;
    .main {
      .container {
        max-width: 1440px;
      }
    }
    &-side {
      float: left;
      &-nav {
        > li {
          float: none;
          width: auto;
          margin-bottom: 0.5em;
        }
      }
    }
    &-content {
      margin-left: 30%;
      padding-top: 1.5%;
      max-width: 900px;
    }
    &-list {
      .box {
        @include one-line;
        .date {
          display: inline-block;
          margin-right: 2em;
          margin-bottom: 0;
        }
      }
    }
    &-detail {
      &-title {
        .date {
          float: right;
          color: $text-color;
          @include fz(15,20);
          font-weight: 400;
          padding-top: 0.3em;
          margin-left: 1em;
        }
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .news {
    &-side {
      &-nav {
        margin-left: 1.5em;
        margin-right: 1.5em;
      }
    }
    &-list {
      .box {
        padding-left: 2.5em;
        padding-right: 2.5em;
        .date {
          margin-right: 4em;
        }
      }
    }
    &-detail {
      &-title {
        @include fz(20px);
        padding: 45px 40px 15px;
      }
      &-txt {
        padding: 20px 40px 45px;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {

}
