.footer {
  padding: 80px 0 25px;
  @include fz(14px);
  &-copyright,
  &-phone {
    background: #fff;
    padding: 0.75em 1.5em;
    margin-bottom: 10px;
  }
  &-phone {
    display: none;
    &::before,
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      background: url(../img/footer_phone_ic.png) no-repeat;
      width: 12px;
      height: 12px;
      margin-top: -0.1em;
    }
    &::before {
      margin-right: 0.5em;
    }
    &::after {
      width: 7px;
      background-position: 100% 0;
      margin-left: 1em;
    }
  }
  &-contact {
    a {
      @include txt-hide;
      background: #fff url(../img/footer_contact_ic.png) no-repeat;
      width: 45px;
      height: 45px;
      margin-right: 10px;
      margin-bottom: 10px;
      &.map {
        background-position: 0 0;
      }
      &.mail {
        background-position: -45px 0;
      }
      &.fb {
        background-position: -90px 0;
      }
      &.line {
        background-position: -135px 0;
      }
      &:hover {
        box-shadow: inset 3px 3px 6px rgba(#000,0.4);
      }
    }
  }
  &-author {
    color: #fff;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.gotop {
  @include txt-hide;
  position: fixed;
  z-index: 100;
  right: 5px;
  bottom: 10px;
  background: url(../img/gotop.png) no-repeat;
  background-size: contain;
  width: 39px;
  height: 33px;
}


@media (min-width: $screen-sm-min) {
  .footer {
    background: #fff;
    padding: 12px 0;
    margin-top: 50px;
    .container {
      max-width: 1440px;
    }
    &-copyright,
    &-phone,
    &-author {
      float: left;
      padding: 0;
      line-height: 45px;
      background: none;
      margin: 0 4em 0 0;
    }
    &-phone {
      display: block;
    }
    &-author {
      color: #666;
      margin: 0;
    }
    &-contact {
      float: left;
      margin-right: 4em;
      a {
        margin: 0;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
  }
}
@media (min-width: 1050px) {
  .footer {
    padding: 15px 0;
    margin-top: 100px;
    &-contact {
      float: right;
      margin-right: 0;
    }
    &-author {
      margin: 0;
    }
  }
  .gotop {
    bottom: 92px;
    width: 52px;
    height: 44px;
  }
}
@media (min-width: $screen-lg-min) {
  .gotop {
    width: (78 * 0.8) + 0px;
    height: (66 * 0.8) + 0px;
  }
}
